<template>
	<div class="box" ref="box" style="margin: 0;padding: 0;"></div>
	<!-- <div id="tag" style="padding:3px 10px;border:#e4e4e4 1px solid;background: #cccccc;font-weight: 550;">
		心率异常
	</div> -->

</template>
<script>
	// 引入three.js
	import * as THREE from "three";
	import {
		GLTFLoader
	} from 'three/examples/jsm/loaders/GLTFLoader.js';
	// 引入CSS3模型对象CSS3DObject
	// import {
	// 	CSS3DObject
	// } from 'three/addons/renderers/CSS3DRenderer.js';
	// 引入CSS3精灵模型对象CSS3DSprite
	// import {
	// 	CSS3DSprite
	// } from 'three/addons/renderers/CSS3DRenderer.js';

	// // 引入CSS3渲染器CSS3DRenderer
	// import {
	// 	CSS3DRenderer
	// } from 'three/addons/renderers/CSS3DRenderer.js';

	//引入轨道控制器扩展库OrbitControls.js
	import {
		OrbitControls
	} from 'three/addons/controls/OrbitControls.js';



	export default {
		name: "ThreeJs",
		data() {
			return {
				width: 500,
				height: 500,
			}
		},
		methods: {
			// 生成一个canvas对象，标注文字为参数name
			createCanvas(name) {
				/**
				 * 创建一个canvas对象，绘制几何图案或添加文字
				 */
				const canvas = document.createElement("canvas");
				const arr = name.split(""); //分割为单独字符串
				let num = 0;
				const reg = /[\u4e00-\u9fa5]/;
				for (let i = 0; i < arr.length; i++) {
					if (reg.test(arr[i])) { //判断是不是汉字
						num += 1;
					} else {
						num += 0.5; //英文字母或数字累加0.5
					}
				}
				// 根据字符串符号类型和数量、文字font-size大小来设置canvas画布宽高度
				const h = 80; //根据渲染像素大小设置，过大性能差，过小不清晰
				const w = h + num * 32;
				canvas.width = w;
				canvas.height = h;
				const h1 = h * 0.8;
				const c = canvas.getContext('2d');
				// 定义轮廓颜色，黑色半透明
				c.fillStyle = "rgba(0,0,0,0.5)";
				// 绘制半圆+矩形轮廓
				const R = h1 / 2;
				c.arc(R, R, R, -Math.PI / 2, Math.PI / 2, true); //顺时针半圆
				c.arc(w - R, R, R, Math.PI / 2, -Math.PI / 2, true); //顺时针半圆
				c.fill();
				// 绘制箭头
				c.beginPath();
				const h2 = h - h1;
				c.moveTo(w / 2 - h2 * 0.6, h1);
				c.lineTo(w / 2 + h2 * 0.6, h1);
				c.lineTo(w / 2, h);
				c.fill();
				// 文字
				c.beginPath();
				c.translate(w / 2, h1 / 2);
				c.fillStyle = "#ffffff"; //文本填充颜色
				c.font = "normal 32px 宋体"; //字体样式设置
				c.textBaseline = "middle"; //文本与fillText定义的纵坐标
				c.textAlign = "center"; //文本居中(以fillText定义的横坐标)
				c.fillText(name, 0, 0);
				return canvas;
			},
			getThreeJs() {
				var width = 1200;
				var height = 800;
				// 创建渲染器
				const renderer = new THREE.WebGLRenderer();
				renderer.shadowMap.enabled = true;
				renderer.setSize(width, height);
				renderer.setClearColor(0xffffff, 0); //设置背景颜色
				const box = this.$refs.box; // 获取元素
				box.appendChild(renderer.domElement); // 将渲染结果添加到目标元素

				// 创建相机
				const camera = new THREE.PerspectiveCamera(
					75,
					width / height,
					0.1,
					100
				);
				camera.position.set(0, 0, 10); // 设置相机位置
				camera.lookAt(0, 0, 0); // 设置相机看向原点

				// 创建场景
				const scene = new THREE.Scene();

				// 设置相机控件轨道控制器OrbitControls
				const controls = new OrbitControls(camera, renderer.domElement);
				// 如果OrbitControls改变了相机参数，重新调用渲染器渲染三维场景
				controls.addEventListener('change', function() {
					renderer.render(scene, camera); //执行渲染操作
				}); //监听鼠标、键盘事件


				const light = new THREE.DirectionalLight('#ffffff', 1.0, 0); //设置平行光源
				light.castShadow = true;
				light.position.set(0, 10, 0) //设置光源向量
				scene.add(light); //追加光源到场景

				// 添加辅助坐标
				const axesHelper = new THREE.AxesHelper(20);
				scene.add(axesHelper);

				// 创建底座
				const geometry0 = new THREE.BoxGeometry(3, 3, 3);
				const material0 = new THREE.MeshPhongMaterial({
					color: 0x00ff00,
					shininess: 20, //高光部分的亮度，默认30
					specular: 0x444444, //高光部分的颜色
				});
				const mesh0 = new THREE.Mesh(geometry0, material0);
				mesh0.position.set(0, -1.5, 0);
				scene.add(mesh0);

				var loader = new GLTFLoader();
				var that = this;
				loader.load(
					'static/stacy_lightweight.glb', // 模型路径
					function(gltf) {
						const model = gltf.scene;

						// const texLoader = new THREE.TextureLoader();
						// const texture = texLoader.load("static/bus.22ea4e33.png");
						const canvas = that.createCanvas('心率异常'); //创建一个canvas画布
						// canvas画布作为CanvasTexture的参数创建一个纹理对象
						// 本质上你可以理解为CanvasTexture读取参数canvas画布上的像素值
						const texture = new THREE.CanvasTexture(canvas);
						const spriteMaterial = new THREE.SpriteMaterial({
							color: '#cccccc', //设置颜色
							map: texture,
						});
						const sprite = new THREE.Sprite(spriteMaterial);
						sprite.scale.set(3, 1, 1);
						sprite.position.set(4.2, 6.5, 0.2); //设置位置，要考虑sprite尺寸影响
						scene.add(sprite);
						
						const canvas2 = that.createCanvas('关节炎'); //创建一个canvas画布
						// canvas画布作为CanvasTexture的参数创建一个纹理对象
						// 本质上你可以理解为CanvasTexture读取参数canvas画布上的像素值
						const texture2 = new THREE.CanvasTexture(canvas2);
						const spriteMaterial2 = new THREE.SpriteMaterial({
							color: '#cccccc', //设置颜色
							map: texture2,
						});
						const sprite2 = new THREE.Sprite(spriteMaterial2);
						sprite2.scale.set(3, 1, 1);
						sprite2.position.set(-4.0, 3.3, 0.2); //设置位置，要考虑sprite尺寸影响

						// obj是建模软件中创建的一个空对象
						//const obj = model.getObjectByName('mixamorigRightUpLeg');
						//tag会标注在空对象obj对应的位置
						//scene.add(sprite);
						scene.add(sprite2);

						model.scale.set(4, 4, 4); // 设置模型大小缩放
						model.position.set(0, 0, 0);
						model.traverse((child) => {
							child.castShadow = true;
							child.receiveShadow = true;
							if (child.isMesh) {
								child.material = new THREE.MeshPhysicalMaterial({
									metalness: 0.2, //玻璃非金属 
									roughness: 0.2, //玻璃表面光滑
									color: '#ECE2BB',
									transmission: 0.5, //玻璃材质透光率，transmission替代opacity 
								});
							}
						});
						scene.add(model);
						renderer.render(scene, camera);
					},
					undefined, // We don't need this function
					function(error) {
						console.error(error);
					}
				);



				const group = new THREE.Group();
				//心率异常
				const geometry1 = new THREE.SphereGeometry(0.2);
				const material1 = new THREE.MeshBasicMaterial({
					color: 'red'
				});
				const mesh1 = new THREE.Mesh(geometry1, material1);
				mesh1.renderOrder = 1000;
				mesh1.material.depthTest = false;
				mesh1.position.set(0.2, 5.5, 0.2);
				group.add(mesh1);
				const material2 = new THREE.LineBasicMaterial({
					color: 'red',
					linewidth: 1
				});
				const points = [];
				points.push(new THREE.Vector3(0.2, 5.5, 0.2));
				points.push(new THREE.Vector3(4.2, 5.5, 0.2));
				points.push(new THREE.Vector3(4.2, 6, 0.2));
				const geometry2 = new THREE.BufferGeometry().setFromPoints(points);
				const line2 = new THREE.Line(geometry2, material2);
				group.add(line2);
				//心率异常

				//关节炎
				const geometry3 = new THREE.SphereGeometry(0.15);
				const material3 = new THREE.MeshBasicMaterial({
					color: 'red'
				});
				const mesh3 = new THREE.Mesh(geometry3, material3);
				mesh3.renderOrder = 1000;
				mesh3.material.depthTest = false;
				mesh3.position.set(-0.55, 2.2, 0.2);
				group.add(mesh3);
				
				const material3a = new THREE.LineBasicMaterial({
					color: 'red',
					linewidth: 1
				});
				const points3 = [];
				points3.push(new THREE.Vector3(-0.55, 2.2, 0.2));
				points3.push(new THREE.Vector3(-4.0, 2.2, 0.2));
				points3.push(new THREE.Vector3(-4.0, 2.8, 0.2));
				const geometry3a = new THREE.BufferGeometry().setFromPoints(points3);
				const line3 = new THREE.Line(geometry3a, material3a);
				group.add(line3);
				// const div = document.getElementById('tag');
				// // HTML元素转化为threejs的CSS2模型对象
				// const tag = new CSS3DSprite(div);
				// tag.position.set(4.2, 6, 0.2);
				// tag.scale.set(0.02, 0.02, 0.02); //缩放标签尺寸
				// group.add(tag);
				scene.add(group);

				// //创建一个CSS2渲染器CSS2DRenderer
				// const css3Renderer = new CSS3DRenderer();
				// css3Renderer.domElement.style.position = 'absolute';
				// css3Renderer.domElement.style.pointerEvents = 'none';
				// css3Renderer.domElement.style.top = '0';
				// css3Renderer.setSize(width, height);
				// css3Renderer.render(scene, camera);
				// box.appendChild(css3Renderer.domElement); // 将渲染结果添加到目标元素
			},
		},
		mounted() {
			this.getThreeJs(); // 执行three的相关代码
		},
	};
</script>
<style scoped>
	.box {
		width: 100%;
		height: 100%;
	}
</style>